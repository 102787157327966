/* RadioButtonMenu.css */
body {
    background-color: #1a1a1a;
    color: #ffffff;
}

.radio-menu-wrapper {
    display: flex;
    justify-content: space-between;
    max-width: 600px;
    margin: 0 auto;
}

.radio-menu {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.radio-menu div {
    flex: 1;
    margin: 0 10px;
}

.radio-menu label {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    border: 1px solid #dbdbdb;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.2s, color 0.2s;
    background-color: #4a4a4a;
    color: #ffffff;
    text-align: center;
}

.radio-menu input[type="radio"] {
    display: none;
}

.radio-menu input[type="radio"]:checked + label {
    background-color: #3273dc;
    color: white;
}

.radio-menu label:hover {
    background-color: #666666;
}

.input {
    background-color: #333333;
    color: #ffffff;
    border: 1px solid #4a4a4a;
}

.input::placeholder {
    color: #b5b5b5;
}

.button {
    background-color: #3273dc;
    color: #ffffff;
    border: none;
}

.button:hover {
    background-color: #275cba;
}
